import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import actionsBuild from "./actions"
import { commonStateBuild, commonGetters } from "../../matching/shared"

const BASE_URI = "/competitors_settings"
const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: true,
  withPagination: true,
  withSorting: true
})

store.mergeState(commonStateBuild())
store.mergeGetters(commonGetters)
store.mergeActions(actionsBuild({ baseURI: BASE_URI }), withLoading)

export default store
