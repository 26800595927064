<template lang="pug">
  .competitors-settings
    .top-bar.align-items-center
      .row
        .filter-actions
          AppSearch(
            @update="applySearch({ search_value: $event, search_type: 'with_competitors' })"
          )
    .table-wrapper
      AppOverlayLoader(:state="loading")
      CompetitorsSettingTable.m-t-20(
        :shops="shops"
        :sorting-data="sorting"
        @sorting="changeSorting"
        )
      AppPagination(
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        :current-page="pagination.current_page"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // store modules
  import competitorsSettingsModule from "@/config/store/competitors/settings"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  const competitorsSettingsMixin = withStoreModule(competitorsSettingsModule, {
    resetState: true,
    name: "competitorsSettings",
    readers: {
      shops: "items",
      loading: "loading",
      pagination: "pagination",
      sorting: "sorting"
    },
    mutations: {
      updateShopByIndex: "SET_ITEM_BY_INDEX",
      setPagination: "SET_PAGINATION_DATA",
      setSorting: "SET_SORTING"
    },
    actions: {
      fetchShops: "FETCH_ITEMS",
      applySearch: "APPLY_SEARCH"
    }
  })

  export default {
    components: {
      AppSearch: () => import("@/components/elements/AppSearch"),
      CompetitorsSettingTable: () => import("./CompetitorsSettingTable"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [competitorsSettingsMixin],

    mounted() {
      this.fetchShops()
    },

    methods: {
      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchShops()
      },

      changeSorting(sorting) {
        this.setSorting(sorting)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.fetchShops()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .competitors-settings
    margin-top: 20px

    .table-wrapper
      position: relative

    .top-bar
      height: 100%
      padding: 0

      .filter
        +filter-title

        &-actions
          margin-top: 20px
          padding: 0 15px
          width: 100%
          display: flex
          align-items: center
          justify-content: space-between
</style>
